<script setup lang="ts">
  const uiStore = useUiStore()
  const emit = defineEmits(["close"])

  const isOpen = ref(false)
  /**
   * The modal element.
   */
  const csModal = ref<HTMLDialogElement | null>(null)
  const props = withDefaults(
    defineProps<{
      size?: "default" | "full"
      closeOnBackdropClick?: boolean
    }>(),
    {
      size: "default",
    },
  )

  const onClose = () => {
    isOpen.value = false
    uiStore.blockScroll = false
    emit("close")
  }

  const close = () => {
    csModal.value?.close()
  }

  defineExpose({
    /**
     * Open the modal.
     */
    openModal: () => {
      isOpen.value = true
      csModal.value?.showModal()
      getFocusableElements()
      uiStore.blockScroll = true
      if (allFocusableElements.value) {
        allFocusableElements.value[0]?.focus()
      }
    },
    /**
     * Close the modal.
     */
    close,
  })

  const baseClasses =
    "p-0 border-none  open:pointer-events-auto pointer-events-none max-w-[initial]"

  const sizeDefault =
    "rounded-none mini:rounded-xl mini:max-w-72 m-x-0 w-screen"

  const sizeFull =
    "rounded-t-xl mini:rounded-xl mini:max-w-150 mini:h-[calc(100vh-theme(spacing.19))] h-[calc(100vh-theme(spacing.12))] mt-12 mini:w-[calc(100vw-theme(spacing.19))]"

  const sizeClasses = computed(() =>
    props.size === "full" ? sizeFull : sizeDefault,
  )

  const animationClasses =
    "open:backdrop:animate-backdropfadein open:animate-fadeslidein"

  const classes = `${baseClasses} ${sizeClasses.value} ${animationClasses}`

  // Handle Click on Backdrop
  const onClick = (e: MouseEvent) => {
    if (props.closeOnBackdropClick && e.target === e.currentTarget) {
      close()
    }
  }

  // Focus Trap
  const focusableElementTypes =
    "a[href], button, input, textarea, select, details"

  const allFocusableElements = ref<NodeListOf<HTMLElement> | undefined>(
    undefined,
  )

  const getFocusableElements = () => {
    allFocusableElements.value = csModal.value?.querySelectorAll<HTMLElement>(
      focusableElementTypes,
    )
  }

  const focusFirst = () => {
    if (allFocusableElements.value) {
      allFocusableElements.value[0]?.focus()
    }
  }

  const focusLast = () => {
    if (allFocusableElements.value) {
      allFocusableElements.value[allFocusableElements.value.length - 1]?.focus()
    }
  }
</script>

<template>
  <dialog ref="csModal" :class="classes" @close="onClose" @click="onClick">
    <div
      role="region"
      aria-live="polite"
      class="opacity-0 absolute inset-0 pointer-events-none user-select-none"
    >
      <template v-if="isOpen">
        <slot name="aria-live" />
      </template>
    </div>
    <div key="firstdiv" tabindex="0" class="focustrap" @focus="focusLast" />
    <slot />
    <div key="seconddiv" tabindex="0" @focus="focusFirst" />
  </dialog>
</template>
